/* You can add global styles to this file, and also import other style files */
@import "~intl-tel-input/build/css/intlTelInput.css";

/* Add application styles & imports to this file! */
@import "./color.scss";
@import '~codemirror/lib/codemirror.css';
@import '~codemirror/addon/lint/lint.css';
@import '~codemirror/addon/hint/show-hint.css';
@import '~codemirror/theme/material';
@import 'codemirror/addon/fold/foldgutter';
.CodeMirror {height: 100%; border-radius: 5px; padding: 3px;}
.CodeMirror-placeholder {color: rgba(0, 0, 0, 0.38) !important;}

$default-font: 'Roboto';

* { font-family: $default-font, sans-serif; box-sizing: content-box;}
.material-icons, .material-icons-outlined {
  font-family: 'Material Icons' !important;
}

html, body {
  margin: 0;
  height: 100%;
  background: #494949;
  font-family: "Roboto", sans-serif;
  color: hsla(0,0%,0%,0.87);
  -webkit-font-smoothing: antialiased;
  overflow-y: hidden;
}

.relative {
  position: relative;
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1;
}

.absolute-center-xy {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.loading-info-container {
    color: #fff;
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 50px;
}
.loading-info-container .loading-text {
    font-size: 18px;
}
.loading-info-container .loading-spinner-container {
    position: relative;
    width: 50px;
    height: 40px;
    margin-left: 7px;
}
.loading-info-container .loading-spinner-container .module-load-spinner {
    opacity: 0.8;
}

/*Module loader*/
.module-load-spinner {
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}

.module-load-spinner > div {
  background-color: #fff;
  height: 100%;
  width: 6px;
  display: inline-block;

  -webkit-animation: module-load-stretchdelay 1.2s infinite ease-in-out;
  animation: module-load-stretchdelay 1.2s infinite ease-in-out;
}

.module-load-spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.module-load-spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.module-load-spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.module-load-spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

input {
  &.err-input {
    outline: none;
    border: 1px solid #f1556c !important;
    background-color: #fff !important;
  }
  &.correct-input {
    border: solid 1px #e0e0e0;
    background-color: #e5f2ff;
  }
}

.sub-modal {
  .disable-full-screen {
    height: calc(100% - 52px) !important;
  }
}

.color-picker-btn {
  padding: 6px 10px;
  border: 0px;
  background: hsl(210, 100%, 56%);
  color: #fff;
  cursor: pointer;
  transition: .2s;
}
.color-picker-btn:hover {
  background: hsl(210, 100%, 70%);
}

/* ngx-slider */
.open {
  .ngx-slider-floor {
    visibility: hidden !important;
  }
}

.close {
  .ngx-slider-ceil {
    visibility: hidden !important;
  }
}

@-webkit-keyframes module-load-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes module-load-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% {
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}

.zsiq_cnt {
  display:none !important;
}

.zsiq_floatmain {
  z-index: 10000 !important;
}

.link-txt {
  color: $primary;
  cursor: pointer;
}

.btn-area {
  // height: 36px;
  display: flex;
  flex-direction: row;
  .button {
    display: flex;
    position: relative;
    justify-content: center;
    text-align: center;
    // padding: 8px 16px;
    // margin-left: 0px;
    overflow: hidden;
    border: none;
    font-size: 14px;
    font-weight: 400;
    color: #2383e2;
    text-decoration: none;
    cursor: pointer;
    outline: none;
    border-radius: 4px;
    -webkit-transition-duration: 0.2s; /* Safari */
    transition-duration: 0.2s;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    &.small {
      font-size: 14px;
      .btn-inner {
        margin: 7px 10px;
      }
    }
    .btn-inner {
      display:flex;
      align-items: center;
      margin: 5px 14px;
      user-select: none;
      div {
        display: flex;
      }
      &.small-btn {
        margin: 4px 8px;
        font-size:14px;
      }
      &.dense-icon-btn {
        margin: 6px 10px 6px 6px;
      }
      &.dense {
        margin: 2px 10px;
      }
      .btn-icon {
        margin-right: 5px;
      }
    }
    // span {
    //   line-height: 20px;
    //   margin: 8px 16px;
    // }
  }
  .button-primary {
    background-color: #2383e2 !important;
    color: #fff  !important;
    &:hover {
      background-color: hsl(210, 100%, 70%) !important;
      // box-shadow: 0px 3px 7px #888;
    }
  }
  .button-danger{
    background-color: #CD3D64 !important;
    color: #fff  !important;
    border-radius: 4px;
    &:hover {
      background-color: hsl(351, 85%, 70%) !important;
    }
  }
  .button-normal {
    border-style: solid;
    border-width: 1px;
    border-image-source: linear-gradient(to bottom, #dfdfdf, #bcbcbc);
    border-image-slice: 1;
    background-image: linear-gradient(to bottom, #f7f7f7, #ececed), linear-gradient(to bottom, #dfdfdf, #bcbcbc);
    background-origin: border-box;
    background-clip: content-box, border-box;
    color: hsla(0, 0%, 0%, 0.6) !important;
    &:hover {
      border-image-source: linear-gradient(to bottom, #c3c3c3, #9f9f9f);
      border-image-slice: 1;
      background-image: linear-gradient(to bottom, #e4e4e4, #d9d9d9), linear-gradient(to bottom, #c3c3c3, #9f9f9f);
    }
    &:active {
      box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, 0.5);
    }
  }
  .button-white {
    background: #fff;
    border: 1px solid hsla(0,0%,0%,0.1);
    border-radius: 5px;
    color: hsla(0, 0%, 0%, 0.87) !important;
    box-shadow: 0px 0px 5px hsla(0,0%,0%,0.1);
    &:hover {
      box-shadow: 0px 0px 10px hsla(0,0%,0%,0.15);;
    }
    &:active {
      box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, 0.5);
    }
  }
  .button-elevation {
    border: solid 1px transparent;
    &:hover {
      border-style: solid;
      border-width: 1px;
      border-image-source: linear-gradient(to bottom, #dfdfdf, #bcbcbc);
      border-image-slice: 1;
      background-image: linear-gradient(to bottom, #fff, #fff), linear-gradient(to bottom, #dfdfdf, #bcbcbc);
      background-origin: border-box;
      background-clip: content-box, border-box;
      color: hsla(0, 0%, 0%, 0.87) !important;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    }
    &:active {
      box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, 0.5);
    }
    .btn-inner {
      margin: 0px 8px;
    }
  }
  .text-button {
    margin: 5px;
    &.text-button-primary {
      color:#1e88e5;
      &:hover {
        color: hsl(208, 79%, 40%);
        text-decoration: underline;
      }
    }
    &.text-button-danger {
      color: #CD3D64;
      &:hover {
        color: 	hsl(342, 65%, 50%);
        text-decoration: underline;
      }
    }
  }
  .space {
    width:8px;
    flex: 1;
  }
}

.btn-disabled {
  cursor: default;
  opacity: 0.3;
  pointer-events: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.tail {
  display: flex;
  flex-direction: row-reverse;
  height: 36px;
  justify-content: center;
  padding: 0px 32px 30px 32px;
  .button {
    display: flex;
    position: relative;
    justify-content: center;
    text-align: center;
    align-items: center;
    // padding: 8px 16px;
    // margin-right: 8px;
    margin-left: 14px;
    overflow: hidden;
    border: none;
    font-size: 16px;
    font-weight: 500;
    color: #2383e2;
    text-decoration: none;
    cursor: pointer;
    outline: none;
    border-radius: 5px;
    -webkit-transition-duration: 0.2s; /* Safari */
    transition-duration: 0.2s;
    &.small {
      font-size: 14px;
      .btn-inner {
        margin: 7px 14px;
      }
    }
    .btn-inner {
      display:flex;
      align-items: center;
      margin: 7px 14px;
      .material-icons {
        padding-right:3px;
      }
      div {
        display: flex;
      }
      &.small-btn {
        margin: 7px 14px;
        font-size:14px;
      }
    }
  }
  .button-primary {
    background-color: #2383e2;
    color: #fff;
    &:hover {
      background-color: hsl(210, 100%, 70%);
      // box-shadow: 0px 3px 7px #888;
    }
  }
  .button-danger{
    background-color: #CD3D64;
    color: #fff;
    border-radius: 4px;
    &:hover {
      background-color: hsl(351, 85%, 60%);
    }
  }
  .button-normal {
    border-style: solid;
    border-width: 1px;
    border-image-source: linear-gradient(to bottom, #dfdfdf, #bcbcbc);
    border-image-slice: 1;
    background-image: linear-gradient(to bottom, #f7f7f7, #ececed), linear-gradient(to bottom, #dfdfdf, #bcbcbc);
    background-origin: border-box;
    background-clip: content-box, border-box;
    color: hsla(0, 0%, 0%, 0.6);
    &:hover {
      border-image-source: linear-gradient(to bottom, #c3c3c3, #9f9f9f);
      border-image-slice: 1;
      background-image: linear-gradient(to bottom, #e4e4e4, #d9d9d9), linear-gradient(to bottom, #c3c3c3, #9f9f9f);
    }
    &:active {
      box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, 0.5);
    }
  }
  .space {
    flex: 1;
  }
}

.items-area  {
  position: relative;
  .item {
    // display: block;
    padding-bottom: 28px;
    // padding: 0 24px;
    &.sub-item {
      padding-bottom: 14px;
      .sub-contents {
        margin-top: 7px;
      }
      .input-contents {
        margin-top: 0px;
      }
    }
    .header-title {
      font-size:24px;
      color: hsla(0, 0%, 0%, 0.87);
    }
    .semi-label {
      margin: 14px 0px 10px 0px;
      font-size: 14px;
      color: hsla(0,0%,0%,0.87);
      .semi-label-sub {
        color: hsla(0,0%,0%,0.6);
      }
    }
    &:nth-last-child(1){
      padding-bottom: 0px;
    }
    &.last {
      padding-bottom: 0px;
    }
    .sub-err {
      position: absolute;
      bottom: -6px;
      font-size:12px;
      color: #f1556c;
    }
    .item-label {
      padding-bottom: 10px;
    }
  }
}

.tail-btn-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #ffffff;
  padding: 14px 20px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  background: transparent;
  .button {
    display: flex;
    position: relative;
    justify-content: center;
    text-align: center;
    // padding: 8px 16px;
    // margin-right: 8px;
    margin-left: 14px;
    overflow: hidden;
    border: none;
    font-size: 14px;
    font-weight: 400;
    color: #2383e2;
    text-decoration: none;
    cursor: pointer;
    outline: none;
    border-radius: 4px;
    -webkit-transition-duration: 0.2s; /* Safari */
    transition-duration: 0.2s;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    &:after {
      content: "";
      background: #f1f1f1;
      display: block;
      position: absolute;
      padding-top: 300%;
      padding-left: 350%;
      margin-left: -20px !important;
      margin-top: -120%;
      opacity: 0;
      transition: all 0.8s;
    }
    &:active:after {
      padding: 0;
      margin: 0;
      opacity: 1;
      transition: 0s
    }
    &.small {
      font-size: 14px;
      .btn-inner {
        margin: 7px 14px;
      }
    }
    .btn-inner {
      display:flex;
      align-items: center;
      margin: 7px 14px;
      .material-icons {
        padding-right:3px;
      }
      div {
        display: flex;
      }
      &.small-btn {
        margin: 7px 14px;
        font-size:14px;
      }
    }
  }
  .button-primary {
    background-color: #2383e2;
    color: #fff;
    &:hover {
      background-color: hsl(210, 100%, 70%);
      // box-shadow: 0px 3px 7px #888;
    }
  }
  .button-danger{
    background-color: #CD3D64;
    color: #fff;
    border-radius: 4px;
    &:hover {
      background-color: hsl(351, 85%, 60%);
    }
  }
  .button-normal {
    border-style: solid;
    border-width: 1px;
    border-image-source: linear-gradient(to bottom, #dfdfdf, #bcbcbc);
    border-image-slice: 1;
    background-image: linear-gradient(to bottom, #f7f7f7, #ececed), linear-gradient(to bottom, #dfdfdf, #bcbcbc);
    background-origin: border-box;
    background-clip: content-box, border-box;
    color: hsla(0, 0%, 0%, 0.6);
    &:hover {
      border-image-source: linear-gradient(to bottom, #c3c3c3, #9f9f9f);
      border-image-slice: 1;
      background-image: linear-gradient(to bottom, #e4e4e4, #d9d9d9), linear-gradient(to bottom, #c3c3c3, #9f9f9f);
    }
    &:active {
      box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, 0.5);
    }
  }
  .button-white {
    background: #fff;
    border: 1px solid hsla(0,0%,0%,0.1);
    border-radius: 5px;
    color: hsla(0, 0%, 0%, 0.87) !important;
    box-shadow: 0px 0px 5px hsla(0,0%,0%,0.1);
    &:hover {
      box-shadow: 0px 0px 10px hsla(0,0%,0%,0.15);;
    }
    &:active {
      box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, 0.5);
    }
  }
  .space {
    flex: 1;
  }
}

.tail-btn-area .button:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s
}

.disabled div{
  cursor: default;
  filter: grayscale(100%);
  pointer-events: none;
  // color: hsla(0, 0%, 50%, 0.5) !important;
  user-select: none;
}

.search-container {
  position: relative;
  display: flex;
  align-items: center;
  width:300px;
  height:36px;
  border-radius: 5px;
  color: #37352F;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  text-indent: 10px;
  transition: 0.2s;
  > i {
    color: rgba(0, 0, 0, 0.3);
  }
  > input {
      width: 100%;
      height: 30px;
      border:none;
      background: transparent;
      font-size: 14px;
      &:focus {
          border:none;
          outline: none;
      }
      &::placeholder {
          color:hsla(0,0%,0%,0.3);
      }
  }
  &.search-focus {
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
      background-color: #ffffff;
  }
  .search-close {
      display: flex;
      align-items: center;
      color: hsla(0, 0%, 0%,0.3);
      cursor: pointer;
      padding: 10px 6px;
  }
}

.item .contents input {
  width: 100%;
  box-sizing: border-box;
  padding: 8px;
  margin: 7px 0px 0px 0px;
  background-color: #fff;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  font-size: 16px;
  transition: .2s;
  text-indent: 0px;
  font-weight: 400;
  &:focus {
    outline: none;
    box-shadow: 0px 0px 0px 3px hsla(210, 100%, 56%, 0.38);
  }

  &.dark {
    background-color: hsl(0, 0%, 96%);
    &:focus {
      background-color: #fff;
    }
  }
  &.disabled {
    background-color: #eeeeee;
    opacity: 1;
  }
  &.err {
    outline: none;
    border: 1px solid #f1556c !important;
    background-color: #fff !important;
  }
  &.correct {
    border: solid 1px #e0e0e0;
    background-color: #e5f2ff;
  }
  &::placeholder {
    color: hsla(0,0%,0%,0.3);
  }
  &.input-err {
    outline: none;
    background-color: hsl(351, 85%, 90%);
    box-shadow: 0px 0px 0px 2px #f1556c;
    &:focus {
      outline: none;
      box-shadow: 0px 0px 0px 2px #f1556c;
    }
  }
}

// POSITION
.row {
  display: flex;
  &.row-reverse {
    flex-direction: row-reverse;
  }
}
.v-center {
  display: flex;
  align-items: center;
}

.cell {
  flex-direction: column;
  padding-bottom: 14px;
}

.between {
  justify-content: space-between;
}

.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-end {
  justify-content: flex-end;
}

.align-center {
  align-items: center;
}

.f-1 {
  flex:1 !important;
}
.f-2 {
  flex:2 !important;
}
.f-3 {
  flex:3 !important;
}
.f-4 {
  flex:4 !important;
}
.f-5 {
  flex:5 !important;
}
.f-6 {
  flex:6 !important;
}
.f-7 {
  flex:7 !important;
}
.f-8 {
  flex:8 !important;
}
.f-9 {
  flex:9 !important;
}

.w-100 {
  width: 100%;
}

.right {
  text-align: right;
}

.selectbox {
  // margin-top: 14px;
  outline: none;
  border-radius: 4px;
  -webkit-transition-duration: 0.2s; /* Safari */
  transition-duration: 0.2s;
  padding: 7px 0px 7px 10px;
  transition: .2s;
  cursor: pointer;
  display:flex;
  font-size:14px;
  flex-direction: row;
  border-radius: 4px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  // background-image: linear-gradient(to bottom, #f7f7f7, #ececed), linear-gradient(to bottom, #dfdfdf, #bcbcbc);
  // background-origin: border-box;
  // background-clip: content-box, border-box;
  color: hsla(0, 0%, 0%, 0.87);
  font-weight: 400;
  &.select-label {
    max-width: 100%;
    font-size:14px;
    padding:6px 14px;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
  }
}

.selectbox.label {
  min-height: 31px;
  max-width: 100%;
  font-size:14px;
  padding:2px 6px 2px 10px;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}


.search-list {
  // max-width: 329px;
  position: absolute;
  /* top: 40px; */
  width: 100%;
  background: #fff;
  font-size: 14px;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  border-color: #dfdfdf;
  box-sizing: border-box;
  overflow-x: hidden;
  white-space: nowrap;
  z-index: 500;
  font-weight: 400;
  .search-result {
    display: flex;
    padding: 7px 14px;
    font-size: 14px;
    color: hsla(0, 0%, 0%, 0.87);
  }
}

.center {
  text-align: center !important;
}

.flex-end {
  align-items: flex-end;
}

.checked {
  font-size: 20px;
  color: #1abc9c;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.menu-fold {
  position: absolute;
  top: 100px;
  left: 0px;
  z-index: 4;
  cursor: pointer;
}

.select-container {
  position: relative;
  min-width: 250px;
}

.select-list{
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  background: #fff;
  font-size: 14px;
  border-radius: 4px;
  overflow-x: hidden;
  white-space: nowrap;
  z-index: 500;
  margin-top: 7px;
  box-shadow: 0 0 0 1px rgba(136,152,170,0.1),0 15px 35px 0 rgba(49,49,93,0.1),0 5px 15px 0 rgba(0,0,0,0.08);
  transition: transform .3s cubic-bezier(0.075, 0.82, 0.165, 1) ,opacity .3s cubic-bezier(0.075, 0.82, 0.165, 1);

  .search-result {
    display: flex;
    padding: 7px 14px;
    font-size: 14px;
    color: #3c4257;
    cursor: pointer;
  }
  .search-result-input {
      font-size: 14px;
      color: #3c4257;
      .list-search-input {
        width: 100%;
        outline: none;
        border: 0;
        border-bottom: 1px solid hsla(0,0%,0%,0.1);
        height: 30px;
        text-indent: 16px;
        &::placeholder {
          color:hsla(0,0%,0%,0.3);
        }
      }
  }
  .select-result {
    display: flex;
    padding: 8px 14px;
    font-size: 14px;
    color: #3c4257;

    &:hover{
      background-color: #f7fafc;
    }
    .check-area {
      display:flex;
      margin-right: 5px;
      justify-content: center;
      color: #3c4257;
      .check-icon {
        font-size: 18px;
        &.checked {
          color: hsl(210, 100%, 56%);
        }
      }
    }
  }
  .btn-area {
    margin: 14px;
  }
}

.err-txt {
  color: $danger;
  font-size: 12px;
}

.custom-tel-input {
  box-sizing: border-box;
  padding: 7px 10px;
  padding-left: 98px;
  border: 1px solid hsla(0,0%,0%,0.2);
  border-radius: 5px;
  min-width: 360px;
  &:focus {
    transition: all 0.2s;
    outline: none;
    box-shadow: 0px 0px 0px 3px hsla(210, 100%, 56%, 0.38);

  }
}

.custom-tel-input-procedure {
  box-sizing: border-box;
  padding: 7px 10px;
  padding-left: 98px;
  border: 1px solid hsla(0,0%,0%,0.2);
  border-radius: 5px;
  width: 100%;
  &:focus {
    transition: all 0.2s;
    outline: none;
    box-shadow: 0px 0px 0px 3px hsla(210, 100%, 56%, 0.38);
  }
}

.underline {
  text-decoration: underline;
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  cursor:pointer;
} /* this targets the default scrollbar (compulsory) */
::-webkit-scrollbar-track:hover {
  background-color: transparent;
}
::-webkit-scrollbar-track {
} /* the new scrollbar will have a flat appearance with the set background color */

::-webkit-scrollbar-track-piece {
    background-color: transparent;
    opacity: 0.2;
}

::-webkit-scrollbar-thumb {
  background-color: hsla(0, 0%, 0%, 0.2);
  border-radius: 6px;
  border:2px solid transparent;
  cursor:pointer;
  background-clip: padding-box;
  transition: .2s;
} /* this will style the thumb, ignoring the track */

::-webkit-scrollbar-button {
  background-color: transparent;
  height: 0px;
  width: 0px;
  display: none;
} /* optionally, you can style the top and the bottom buttons (left and right for horizontal bars) */

::-webkit-scrollbar-corner {
  height: 0px;
  width: 0px;
  display: none;
  // background-color: black;
} /* if both the vertical and the horizontal bars appear, then perhaps the right bottom corner also needs to be styled */

::-webkit-scrollbar-thumb:horizontal {
  background-color: hsla(0, 0%, 0%, 0.2);
  border-radius: 6px;
  border:2px solid transparent;
  cursor:pointer;
  background-clip: padding-box;
  transition: .2s;
}

:focus {
  outline: none;
}

/* firefox */

* {
  scrollbar-color: rgba(0, 0, 0, 0.2) #fff;
  scrollbar-width: thin;
}

//zoho zsiq_floatmain
@media screen and (max-width: 1200px) {
  .zsiq_floatmain{
    display: none !important;
  }
}


/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */

/* position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}
.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}
.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-left {
  top: 12px;
  left: 12px;
}
.toast-top-right {
  top: 12px;
  right: 12px;
}
.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}
.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}
.toast-message {
  word-wrap: break-word;
}
.toast-message a,
.toast-message label {
  color: #FFFFFF;
}
.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}
.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}
.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}
/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}
.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}
.toast-container * {
  box-sizing: border-box;
}
.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
}
.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z'/%3E%3C/svg%3E");
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/%3E%3C/svg%3E");
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E");
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' width='576' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3E%3C/svg%3E");
}
.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}
.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}
.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}
.toast-success {
  background-color: #51A351;
}
.toast-error {
  background-color: #BD362F;
}
.toast-info {
  background-color: #2F96B4;
}
.toast-warning {
  background-color: #F89406;
}
.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}

// Select Tag
.inner-selectbox {
  height: 33px;
	padding: 6px 20px 6px 10px;
	box-sizing: border-box;
	border-radius: 5px;
	cursor: pointer;
	border: 1px solid hsla(0, 0%, 88%, 1);
	box-shadow: 0 0 5px 0 hsla(0, 0%, 0%, 0.1);
	&:hover {
		box-shadow: 0px 0px 6px 0px hsla(0, 0%, 0%, 0.15);
	}
}

//Slider
.ngx-slider-bubble {
  bottom: 16px !important;
  padding: 5px 14px;
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #2383e2 !important;
}
.ngx-slider-floor {
  padding: 5px 14px 5px 0px !important;
}
.ngx-slider-ceil {
  padding: 5px 0px 5px 14px !important;
  transform: translateX(-9px);
}
.value-slider {
	width: 100%;
	.ngx-slider .ngx-slider-bar {
		background: hsl(0, 0%, 92%);
		height: 4px;
		top: 6px;
		border-radius: 6px;
		left: 11px;
		width: calc(100% - 14px);
	}
	.ngx-slider .ngx-slider-selection {
		background: hsl(210, 100%, 56%);
	}
	.ngx-slider .ngx-slider-pointer {
		// top: auto; /* to remove the default positioning */
		// bottom: 0;
		width: 20px;
		height: 20px;
		background-color: #fff;
		border: 1px solid #dcdcdc;
		top: -20px;
		outline: 0;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
		&:hover {
			background-color: #eeeeee;
		}
	}
	.ngx-slider .ngx-slider-pointer:after {
		display: none;
	}
	.ngx-slider .ngx-slider-bubble {
		bottom: 23px;
		padding: 5px 14px;
		font-size: 16px;
		color: #2383e2;
	}
	.ngx-slider .ngx-slider-limit {
		font-weight: bold;
		// color: hsl(210, 100%, 56%);
	}
	.ngx-slider .ngx-slider-tick {
		background: hsl(0, 0%, 92%);

		width: 18px;
		height: 18px;
		top: -4px;
	}
	.ngx-slider .ngx-slider-tick.ngx-slider-selected {
		background: hsl(210, 100%, 56%);
	}
	.ngx-slider.animate .ngx-slider-bubble {
		transition: all linear 0s;
	}
	.ngx-slider.animate .ngx-slider-bar-wrapper {
		transition: all linear 0s;
	}
	.ngx-slider.animate .ngx-slider-pointer {
		transition: all linear 0s;
	}
}

/* TAGS */
select {
  width: 100%;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-image: url("data:image/svg+xml;utf-8,<svg xmlns='http://www.w3.org/2000/svg' height='18px' width='14px' viewBox='0 0 18 18'><path d='M0 0h24v24H0V0z' fill='none'/><path d='M12 4L16.58 8.59L15.17 10L12 6.83L8.83 10L7.41 8.59L12 4Z'/><path d='M12 17.17L15.17 14 L16.59 15.41L12 20L7.42 15.41L8.83 14L12 17.17Z'/></svg>") !important;
  background-repeat: no-repeat !important;
	background-position: right 10px top calc(50% - 3px) !important;
  cursor:pointer;
  .no-info {
    color: rgba(0, 0, 0, 0.38);
  }
}

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}

// material Style
.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.2);
}

.md-drppicker .calendar-time .select select {
  background-position: right 1px top 40%;
}

// layout
$count: 50;
@for $i from 0 through $count {
  .m-#{$i} {
    margin: $i * 1px;
  }
  .mt-#{$i} {
    margin-top: $i * 1px;
  }
  .mr-#{$i} {
    margin-right: $i * 1px;
  }
  .mb-#{$i} {
    margin-bottom: $i * 1px;
  }
  .ml-#{$i} {
    margin-left: $i * 1px;
  }
  .p-#{$i} {
    padding: $i * 1px;
  }
  .pt-#{$i} {
    padding-top: $i * 1px;
  }
  .pr-#{$i} {
    padding-right: $i * 1px;
  }
  .pb-#{$i} {
    padding-bottom: $i * 1px;
  }
  .pl-#{$i} {
    padding-left: $i * 1px;
  }
  .fs-#{$i} {
    font-size: $i * 1px;
  }
}

/* Global Tooltip */
.--g-tooltip-content {
  margin-left: 8px;
}

.--g-tooltip-content:hover .--g-tooltip {
  visibility: visible;
  opacity: 1;
}

.--g-tooltip-icon {
  cursor: pointer;
}

.--g-tooltip {
  position: fixed;
  max-width: 400px;
  height: fit-content;
  visibility: hidden;
  background-color: #fff;
  padding: 14px;
  box-sizing: border-box;
  border-radius: 5px;
  font-size:14px;
  box-shadow: rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.1) 0px 9px 24px;

  /* Position the tooltip text */
  z-index: 100;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
  transition-delay: 0.25s;

  /* Font */
  color: hsla(0, 0%, 0%, 0.87);
  word-spacing: 1px;
  word-break: keep-all;
  white-space: break-spaces;
}

.tooltip-background {
  padding: 4px 8px; 
  background: rgba(0, 0, 0, .7); 
  color: white;
}

.tooltip-background-white {
  padding: 4px 8px; 
  background: white; 
  color: rgba(0, 0, 0, .7);
}

// pointer
.pointer {
  cursor: pointer;
}

// editor js
.ce-block__content, .ce-toolbar__content { 
  max-width: calc(100% - 100px) !important; 
} 
.cdx-block { max-width: 100% !important; }

// date-picker
.md-drppicker td.active {
  background-color: #2383e2 !important;
}

.md-drppicker .ranges ul li {

  &:hover {
    background-color: transparent;
  }

  button {
    width: calc(100% - 10px);
    box-sizing: border-box;
    border-radius: 3px;

    &.active {
      background-color: #2383e2 !important;
    }
    &:hover {
      background-color: #eee;
    }
  }
}

.md-drppicker .btn.btn-default {
  background-color: white !important;
}

.md-drppicker .btn {
  padding: 7px 14px !important;
  font-size: 14px !important;
  line-height: 14px !important;
  background-color: #2383e2 !important;
  margin: 0px 20px 7px 0px !important;
  box-shadow: none !important;
  text-transform: capitalize !important;
}

.md-drppicker .clear svg {
  display: none;
}