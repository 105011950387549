@import '~@angular/material/theming';
@include mat-core();

$custom-primary: mat-palette($mat-blue);
$custom-accent: mat-palette($mat-blue, A200, A100, A400);
$custom-theme: mat-light-theme($custom-primary, $custom-accent);

@include angular-material-theme($custom-theme);

.c_dialog_container {
  .mat-dialog-container {
    padding: 0;
  }
}
