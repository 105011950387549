$primary: #1E88E5;
$primary-shadow: #1A78CA;
$primary-tint: #3594E8;
$secondary: #5469D4;
$secondary-shadow: #4A5CBB;
$secondary-tint: #6578D8;
$danger: #CD3D64;
$danger-shadow: #B43658;
$danger-tint: #D25074;
$warning: #F5925E;
$warning-shadow: #D88053;
$warning-tint: #F69D6E;
$success: #1EA675;
$success-shadow: #1A9267;
$success-tint: #35AF83;
$gray-high: rgba(0, 0, 0, 0.87);
$gray-medium: rgba(0, 0, 0, 0.60);
$gray-low: rgba(0, 0, 0, 0.38);
$border: rgba(0, 0, 0, 0.10);

$blue-gray-higher: #55647B;
$blue-gray-high: #8792A2;
$blue-gray-medium: #E3E8EE;
$blue-gray-lower: #F2F6F9;
$blue-gray-low: #F7FAFC;
$armed: #FF7F16;
$armed-shadow: #E07013;
$armed-tint: #FF8C2D;
$armed-shadow: #E07013;